




































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Input from '@/components/common/elements/Input.vue';
import Select from '@/components/common/elements/Select.vue';
import Label from '@/components/common/elements/Label.vue';
import { SelectProps } from '@/constants/form/types';

@Component({
  components: { Input, Select, Label }
})
export default class SelectComboBoxArea extends Vue {
  // name属性
  @Prop({ default: '' })
  private name!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private label!: string;
  // 選択value
  @Prop({ default: '' })
  private value?: string;
  // 入力可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // rulesの付与
  @Prop({ default: '' })
  private rules?: string;
  // セレクトBOXに表示する選択肢
  @Prop({ default: () => ({ name: '', options: [] }) })
  private selectData!: SelectProps;
  public selectText = '';

  // v-modelに格納
  beforeMount() {
    if (this.value !== undefined && this.selectData !== undefined) {
      this.selectText = this.value;
    }
  }

  // 選択・入力イベントにて共にvalueが変化するため捕まえて表示切替
  @Watch('value')
  watchValue() {
    if (this.value !== undefined && this.selectData !== undefined) {
      this.selectText = this.value;
    }
  }

  @Emit('inputOperation')
  public inputOperation(value: { [k: string]: string }) {
    const val = typeof value === 'string' ? value : value.text;
    return { name: this.name, value: val };
  }
}
