


































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { SelectProps, InputResponse } from '@/constants/form/types';

@Component({})
export default class Radio extends Vue {
  // name属性
  @Prop({ default: '' })
  private name!: string;
  // ラジオデータ
  @Prop({ default: () => ({ name: '', options: [] }) })
  public selectData!: SelectProps;
  // 読み取り専用か
  @Prop({ default: false })
  public isReadonly?: boolean;
  // 初期選択value
  @Prop({ default: '' })
  public value?: '';
  // 入力要素名
  @Prop({ default: '' })
  public label?: string;
  // 入力チェック
  @Prop({ default: '' })
  private rule?: string;

  public radioGroup = '';

  mounted() {
    if (this.value) {
      this.radioGroup = this.value;
    }
  }

  // 明細行削除時対応の為ValueをWatch
  @Watch('value')
  watchValue() {
    if (this.value !== undefined) {
      this.radioGroup = this.value;
    }
  }

  /**
   * 親コンポーネントに選択値を通知する
   * @module inputOperation
   * @return InputResponse - 要素名と入力値
   */
  @Emit('changeRadio')
  public changeRadio(): InputResponse {
    return { name: this.name, value: this.localValue };
  }

  private get localValue(): string {
    return this.radioGroup;
  }

  private set localValue(value: string) {
    this.radioGroup = value;
  }

  createName() {
    if (this.label !== undefined) {
      const keyStr = this.name.match(/\[\d{1,}\]/);
      return keyStr !== null ? this.label + keyStr : this.label;
    }
    return '';
  }
  createMessage(errors: any) {
    const result: string[] = [];
    for (const i in errors) result.push(errors[i].replace(/\[\d{1,}\]/, ''));
    return result;
  }
}
