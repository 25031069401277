/*
 * 機能別コンポーネント マスタ参照ダイアログで使用するconst・interface・types
 * ・更新履歴
 *   2021/01/19 ファイル作成 小林
 */
/** マスタ参照ダイアログに表示するテスト用仮ラベル */
export const LabelName = ['大分類', '小分類', '品目名'];
/** マスタ参照ダイアログに表示するボタンラベル */
export const ButtonName = ['検索', 'クリア'];

/* ComponentListCommonModules.vueのサンプル実装で読み込んで使用しているサンプルデータ */
export const ItemData = {
  header: [
    // { value: 'category1_id', text: '', width: '20%' },
    // { value: 'class', text: '勘定科目', width: '20%' },
    // { value: 'category1_name', text: '', width: '20%' },
    // { value: 'account_title_id', text: '', width: '20%' },
    // { value: 'category2_id', text: '', width: '20%' },
    // { value: 'category2_name', text: '', width: '20%' },
    { value: 'id', text: '品目コード', width: '20%' },
    { value: 'name', text: '品目名', width: '20%' },
    // { value: 'tax_class_id', text: '', width: '20%' },
    { value: 'standard_unit_price', text: '標準単価', width: '20%' },
    { value: 'class', text: '勘定科目', width: '20%' }
  ],
  body: [
    {
      category1_id: 'g01',
      class: '製造原価',
      category1_name: '外注処理費外注工賃',
      account_title_id: 'a1000000',
      category2_id: 'g0101',
      category2_name: '作業代',
      id: 'g0101001',
      name: '外注作業',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0201',
      category2_name: '残業食事代',
      id: 'g0201001',
      name: '残業食事代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0202',
      category2_name: '制服',
      id: 'g0202001',
      name: '貸与事務服代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0202',
      category2_name: '制服',
      id: 'g0202002',
      name: '貸与作業服代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0202',
      category2_name: '制服',
      id: 'g0202003',
      name: '制服クリーニング代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0203',
      category2_name: '事務所用雑費',
      id: 'g0203001',
      name: '茶菓代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0203',
      category2_name: '事務所用雑費',
      id: 'g0203002',
      name: '事務所用観葉植物',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0203',
      category2_name: '事務所用雑費',
      id: 'g0203003',
      name: '薬品代及び用品代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g02',
      class: '販売管理費',
      category1_name: '厚生費',
      account_title_id: 'a2000000',
      category2_id: 'g0204',
      category2_name: 'その他',
      id: 'g0204001',
      name: 'その他厚生費',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301002',
      name: 'ノート',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301003',
      name: 'ファイル',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301004',
      name: '筆記用具',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301005',
      name: 'のり',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301006',
      name: '消しゴム',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301007',
      name: '紙テープ',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301008',
      name: '伝票類',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301009',
      name: '名刺代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301010',
      name: '包装用紙',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301011',
      name: '名刺代',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    },
    {
      category1_id: 'g03',
      class: '販売管理費',
      category1_name: '事務用消耗品費',
      account_title_id: 'a3000000',
      category2_id: 'g0301',
      category2_name: '月分事務消耗品代',
      id: 'g0301999',
      name: 'その他',
      tax_class_id: 't1000000',
      standard_unit_price: '1000'
    }
  ]
};
