



























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { SelectProps, InputResponse } from '@/constants/form/types';

@Component({})
export default class SelectNoVModel extends Vue {
  // セレクトリストデータ
  @Prop({ default: () => ({ name: '', options: [] }) })
  public selectData!: SelectProps;
  // 選択可能か制御
  @Prop({ default: false })
  public isDisabled?: boolean;
  // 初期選択value
  @Prop()
  public selectValue?: { [k: string]: string };
  // content name
  @Prop({ default: '' })
  public name!: string;

  /**
   * 親コンポーネントに選択値を通知する
   * @module changeSelect
   * @return value - 選択した項目
   */
  @Emit('changeSelect')
  public changeSelect(value: { [k: string]: string }) {
    return { name: this.name, value: value };
  }
}
