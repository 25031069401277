






























































import { DateTime } from 'luxon';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { InputResponse } from '@/constants/form/types';

@Component({})
export default class DatePicker extends Vue {
  // デートピッカーのname属性
  @Prop({ default: '' })
  private name!: string;
  // デートピッカーの初期値
  @Prop({ default: '' })
  private value?: string;
  // デートピッカーの表示タイプ初期値
  @Prop({ default: 'date' })
  private type?: string;
  // デートピッカーの表示形式
  @Prop({ default: '' })
  private format?: string;
  // 入力要素名
  @Prop({ default: '' })
  private label?: string;
  // プレースホルダ―
  @Prop({ default: '' })
  private placeholder?: string;
  // アイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: 'mdi-calendar' })
  private icon?: string;
  // デートピッカーを入力可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // readonly
  @Prop({ default: false })
  private isReadonly?: boolean;
  // デートピッカー内選択ボタン色 (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: 'grey' })
  private sendTextColor?: string;
  @Prop({ default: 'primary' })
  private primary?: string;
  // デートピッカー内の選択可能な過去日を指定
  @Prop({ default: '' })
  private minDate?: string;
  // デートピッカー内の選択可能な未来日を指定
  @Prop({ default: '' })
  private maxDate?: string;
  @Prop({ default: '' })
  private rule?: string;
  // デートピッカー用形式
  public date = '';
  // 表示用形式
  public formatDate = '';
  public menu = false;
  // 選択日付け範囲
  public localMinDate = '';
  public localMaxDate = '';

  mounted() {
    if (this.value !== undefined && this.value !== null && this.value.length > 0) {
      // 画面遷移による初期値で、時分秒が付加されている場合があるため、時分秒を削除する
      // かつUTCをJSTに変換
      this.date = this.editDateFormat(this.value, 'Asia/Tokyo', this.format);
    }
    if (this.minDate !== undefined && this.minDate !== null && this.minDate.length > 0) {
      this.localMinDate = this.minDate;
    }
    if (this.maxDate !== undefined && this.maxDate !== null && this.maxDate.length > 0) {
      this.localMaxDate = this.maxDate;
    }
  }

  @Watch('value')
  watchValue() {
    if (this.value !== undefined && this.value !== null && this.value.length > 0) {
      // 画面遷移による初期値で、時分秒が付加されている場合があるため、時分秒を削除する
      // かつUTCをJSTに変換
      this.date = this.editDateFormat(this.value, 'Asia/Tokyo', this.format);
    }
    if (this.minDate !== undefined && this.minDate !== null && this.minDate.length > 0) {
      this.localMinDate = this.minDate;
    }
    if (this.maxDate !== undefined && this.maxDate !== null && this.maxDate.length > 0) {
      this.localMaxDate = this.maxDate;
    }
  }

  // デートピッカー用値が更新されたら表示用値を更新
  @Watch('date')
  updateDate() {
    this.formatDate = this.editDateFormat(this.date, 'Asia/Tokyo', this.format);
  }
  // 選択対象日付け（Start）の変更監視（主にフォームリセット対策）
  @Watch('minDate')
  updateMinDate() {
    this.localMinDate = this.minDate !== undefined ? this.minDate : '';
  }
  // 選択対象日付け（End）の変更監視（主にフォームリセット対策）
  @Watch('maxDate')
  updateMaxDate() {
    this.localMaxDate = this.maxDate !== undefined ? this.maxDate : '';
  }
  // フォームリセット時など値が変更されたとき各ローカルデータリセット
  @Watch('formatDate')
  updateformatDate() {
    if (this.formatDate === undefined) {
      this.date = '';
      this.localMinDate = '';
      this.localMaxDate = '';
    }
  }

  /**
   * 親コンポーネントに値を通知する
   * @module selectDateOpratipon
   * @param {any} $refs
   * @param {string} date 選択した日付け
   * @return {[k: string]: string} - input要素名と値（返却はデートピッカー形式）
   */
  @Emit('selectDateOpratipon')
  public selectDateOpratipon($refs: any, date: string): InputResponse {
    $refs.menu.save(date);
    return { name: this.name, value: this.date };
  }

  /**
   * Date型のフォーマット変更を行います
   * @param {string} date - 日付け文字列
   * @param {string} zone - タイムゾーン
   * @param {string} format - フォーマット
   * @returns string - フォーマットを変更した日付け文字列
   */
  public editDateFormat(date: string, zone: string, format?: string): string {
    if (date === undefined || typeof date !== 'string' || (typeof date === 'string' && date === ''))
      return '';
    const datetime = DateTime.fromISO(date).setZone(zone);
    return !format || format.length === 0 ? date : datetime.toFormat(format);
  }
}
