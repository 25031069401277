






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';

@Component({
  components: {
    SingleText
  }
})
export default class StatusBox extends Vue {
  // ラベル名
  @Prop({ default: '' })
  private labelName!: string;
  // 表示ステータス名
  @Prop({ default: '' })
  private displayStatus!: string;
  // ラベルのCSSクラス
  @Prop({ default: 'base-label labelClass' })
  private labelAddClass!: string;
  // ステータスのCSSクラス
  @Prop({ default: 'basc-info-panel__status-name status-tag--default' })
  private statusAddClass!: string;
  // ステータスの配置(デフォルトで縦並びでvertical、横並びする場合はhorizontal)
  @Prop({ default: 'vertical' })
  private placement!: string;
}
