






























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { InputResponse } from '@/constants/form/types';

@Component({})
export default class MultipleLineText extends Vue {
  // 入力要素のname属性
  @Prop({ default: '' })
  private name!: string;
  // 入力要素名
  @Prop({ default: '' })
  private label?: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // 入力要素高さ
  @Prop({ default: '5' })
  private rows?: string;
  // バリデーションルール
  @Prop({ default: '' })
  private rules?: string;

  public inputVal = '';

  mounted() {
    if (this.value) {
      this.inputVal = this.value;
    }
  }

  @Watch('value')
  watchValue() {
    this.inputVal = this.value ? this.value : '';
  }

  private get inputValue(): string {
    return this.inputVal;
  }

  private set inputValue(inputVal: string) {
    this.inputVal = inputVal;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return InputResponse - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(): InputResponse {
    return { name: this.name, value: this.inputVal };
  }

  createName() {
    if (this.label !== undefined) {
      const keyStr = this.name.match(/\[\d{1,}\]/);
      return keyStr !== null ? this.label + keyStr : this.label;
    }
    return '';
  }

  createMessage(errors: string[]) {
    const result: string[] = [];
    for (const i in errors) result.push(errors[i].replace(/\[\d{1,}\]/, ''));
    return result;
  }
}
