






















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import Dialog from '@/components/common/modules/Dialog.vue';
import DialogMasterSelectTable from '@/components/common/modules/DialogMasterSelectTable.vue';

@Component({
  components: { InputNoVModel, Label, Dialog, DialogMasterSelectTable }
})
export default class MasterSelectSetControl extends Vue {
  // 要素名
  @Prop({ default: () => [] })
  private name!: Array<any>;

  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns!: Array<any>;

  // テーブルに表示するデータ配列
  @Prop({ default: () => [] })
  private body!: Array<any>;

  // テーブルから取得するデータ配列
  @Prop({ default: () => [] })
  private outPutData!: Array<any>;

  // 初期値
  @Prop({ default: '' })
  private value?: any;

  // ダイヤログ表示のタイトル
  @Prop({ default: '' })
  private dialogTitle?: string;

  // 領域外を押下した際の閉じる処理
  @Prop({ default: false })
  private isPersistent?: boolean;

  // ダイアログの表示制御値
  private dialogStatus = false;

  // 取得ボタンの表示・非表示
  @Prop({ default: true })
  private isBtnVisible?: boolean;

  // rulesの付与
  @Prop({ default: '' })
  private rules?: string;

  // アイコンの付与
  @Prop({ default: '' })
  private appendIcon?: string;

  /**
   * 初期値の設定
   */
  mounted() {
    if (this.value) {
      const findData = this.body.find(v => v.i_id === this.value.i_id);
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        if (findData[field.name]) {
          this.outPutData.push({ ...field, ...{ value: findData[field.name] } });
        }
      });
    }
  }

  @Watch('value')
  onValueChanged() {
    if (this.value) {
      const findData = this.body.find(v => v.i_id === this.value.i_id);
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        if (findData && findData[field.name]) {
          this.outPutData.push({ ...field, ...{ value: findData[field.name] } });
        } else {
          this.outPutData.push({ ...field, ...{ value: '' } });
        }
      });
    } else {
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        this.outPutData.push({ ...field, ...{ value: '' } });
      });
    }
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return data - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: any) {
    return data;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperationRelay
   * @return data - 名前と入力値
   */
  @Emit('catchTableDataRelay')
  public catchTableDataRelay(data: any) {
    const selectData = data.selectData;
    this.outPutData.forEach((field, index) => {
      if (field.name in selectData) {
        this.outPutData[index].value = selectData[field.name];
      }
    });
    // 親コンポーネントへ通知
    this.inputOperation({ name: this.name, value: selectData.i_id });
    this.dialogStatus = false;
    return data.selectData;
  }

  /**
   * ダイアログを使用する場合のイベント取得処理
   * 子コンポーネントからEmitされる値をコンソールに出力
   * （値が渡されるか確認用）
   * @module changeDialogStatus
   * @param {Object} e - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  private changeDialogStatus(): void {
    this.dialogStatus = false;
  }
}
