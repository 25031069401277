var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"autoCreateForm-area",style:({ gridTemplateColumns: _vm.fieldCols })},_vm._l((_vm.fieldData),function(field){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(field.show === true),expression:"field.show === true"}],key:field.field_id,staticClass:"input-area-wrap",class:_vm.addClass,style:({
      /**
      grid用 style を付与
      gridColumnStart=列開始位置,gridColumnEnd=列終了位置 gridRowStart=行開始位置,gridRowEnd=行終了位置
      */
      gridColumnStart: field.col === 0 ? 1 : field.col + 1,
      gridColumnEnd: field.col + field.sizeX + 1,
      gridRowStart: field.row === 0 ? 1 : field.row + 1,
      gridRowEnd: field.row + field.sizeY + 1
    })},[_c(field.component,_vm._b({tag:"component",on:{"inputOperation":function($event){return _vm.catchInput($event)},"emitFileData":function($event){return _vm.emitFileData($event)}}},'component',field.props,false))],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }