








































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Typography from '@/components/common/elements/Typography.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import SelectNoVModel from '@/components/common/elements/SelectNoVModel.vue';
import Table from '@/components/common/elements/Table.vue';
import { TableColumn } from '@/constants/components/table';
import { SelectProps, InputResponse } from '@/constants/form/types';
import { LabelName, ButtonName } from '@/constants/components/dialogMasterSelectMultiControlTable';
import BaseModel from '@/models/baseModel';

@Component({
  components: { SingleText, Typography, Table, InputNoVModel, SelectNoVModel }
})
export default class DialogMasterSelectMultiControlTable extends Vue {
  // ダイアログ開閉状態
  @Prop({ default: false })
  private dialog?: boolean;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログ内表示テキスト
  @Prop({ default: '' })
  private bodyText!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内NGボタン文字
  @Prop({ default: '閉じる' })
  private buttonTextClose?: string;
  // ダイアログ内NGボタン文字色
  @Prop({ default: 'light' })
  private buttonColorNG?: string;
  // ダイヤログの表示切替パラメータ
  @Prop({ default: 'A' })
  private viewParam?: string;
  // 表示アイテム数
  @Prop({ default: -1 })
  private perPage?: number;
  // テーブルに表示するカラム配列
  @Prop({ default: [] })
  private columns!: Array<TableColumn>;
  // テーブルに表示するデータ配列
  @Prop({ default: [] })
  private body!: any;
  // 検索文字列
  private search = '';
  // 画面内で表示するデータ配列
  private bodyData: any = [];
  // セレクトの操作
  @Prop({ default: false })
  private isSelectDisabled?: boolean;
  // 大項目抽出で使用する検索、設定プロパティの配列
  @Prop({ default: {} })
  private searchLargeItemData?: any;
  // 小項目抽出で使用する検索、設定プロパティの配列
  @Prop({ default: {} })
  private searchSmallItemData?: any;
  // ダイヤログ内自由入力項目の見出し
  @Prop({ default: '' })
  private freeFormatLabelName!: string;
  // ダイヤログ内自由入力項目の見出し
  @Prop({ default: '' })
  private freeFormatLabelDisplayId!: string;
  // 画面内で表示するラベル配列
  private labelName = LabelName;
  // 画面内で表示するボタン配列
  private buttonName = ButtonName;
  // 大項目で表示するセレクト用配列
  private largeItem: SelectProps = { name: '', options: [] };
  // 小項目で表示するセレクト用配列
  private smallItem: SelectProps = { name: '', options: [] };
  // 選択されている大項目
  private selectLargeItem: any = { value: '', text: '' };
  // 選択されている小項目
  private selectSmallItem: any = { value: '', text: '' };
  // baseModelの読み込み
  private baseModel = new BaseModel();
  @Prop({ default: '大項目' })
  private searchLargeLabelName?: string;
  @Prop({ default: '小項目' })
  private searchSmallLabelName?: string;
  @Prop({ default: () => [] })
  private sortKey?: Array<{ [k: string]: string }>;
  @Prop({ default: false })
  private sortDesc?: boolean;

  /**
   * 初期値の設定
   */
  mounted() {
    // 大項目のセレクトボックスデータ取得
    this.largeItem = this.baseModel.setSelectItemArrayFormat(
      this.body,
      this.searchLargeItemData.findItemName,
      this.searchLargeItemData.setItemValue,
      this.searchLargeItemData.setItemText
    );
    // 小項目のセレクトボックスデータ取得
    this.smallItem = this.baseModel.setSelectItemArrayFormat(
      this.body,
      this.searchSmallItemData.findItemName,
      this.searchSmallItemData.setItemValue,
      this.searchSmallItemData.setItemText
    );
  }

  @Watch('body')
  watchValue() {
    //  TODO:初期表示する場合に使用
    //  this.bodyData = this.body !== undefined ? this.body : '';
    this.largeItem = this.baseModel.setSelectItemArrayFormat(
      this.body,
      this.searchLargeItemData.findItemName,
      this.searchLargeItemData.setItemValue,
      this.searchLargeItemData.setItemText
    );
    // 小項目のセレクトボックスデータ取得
    this.smallItem = this.baseModel.setSelectItemArrayFormat(
      this.body,
      this.searchSmallItemData.findItemName,
      this.searchSmallItemData.setItemValue,
      this.searchSmallItemData.setItemText
    );
  }

  /**
   * 親コンポーネントに押下したTableの内容を通知する
   * @module catchTableData
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  @Emit('catchTableData')
  private catchTableData(response: any) {
    return { dialog: false, selectData: response };
  }

  /**
   * 「クリア」ボタンを押下した際にInput内の検索文言、項目を削除(初期化)する。
   * @module deleteButtonOperation
   */
  public deleteButtonOperation() {
    this.selectLargeItem = { value: '', text: '' };
    this.selectSmallItem = { value: '', text: '' };
    this.bodyData = [];
    const itemData = this.body;
    this.search = '';
    // 大項目のセレクトボックスデータ取得
    this.largeItem = this.baseModel.setSelectItemArrayFormat(
      itemData,
      this.searchLargeItemData.findItemName,
      this.searchLargeItemData.setItemValue,
      this.searchLargeItemData.setItemText
    );
    // 小項目のセレクトボックスデータ取得
    this.smallItem = this.baseModel.setSelectItemArrayFormat(
      itemData,
      this.searchSmallItemData.findItemName,
      this.searchSmallItemData.setItemValue,
      this.searchSmallItemData.setItemText
    );
  }

  /**
   * ダイヤログ内の初期化を行う、親コンポーネントに押下したか否か通知する
   * @module confirmOpratipon
   * @return {AddDetailResponse} - 要素ステータス、押下判定
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(isAgree: boolean): { [k: string]: boolean } {
    this.deleteButtonOperation();
    return { dialog: false, isAgree: isAgree };
  }

  /**
   * 入力値の更新と親コンポーネントに入力値を通知
   * @module inputOperationRelay
   * @return data - 名前と入力値
   */
  @Emit('inputOperationRelay')
  public inputOperationRelay(data: InputResponse) {
    this.search = data.value;
  }

  /**
   * 「検索」ボタン押下時の検索処理
   * @module searchButtonOperation
   */
  public searchButtonOperation() {
    this.bodyData = [];
    const itemData: Array<any> = this.body;
    let selectItemArray = [];

    if (
      this.selectLargeItem.value === '' &&
      this.selectSmallItem.value === '' &&
      this.search === ''
    ) {
      this.bodyData = this.body;
    } else {
      for (let x = 0; x < itemData.length; x++) {
        let falg = false;
        if (this.selectSmallItem.value === '') {
          if (itemData[x][this.searchLargeItemData.setItemValue] === this.selectLargeItem.value) {
            falg = true;
          }
        } else if (this.selectLargeItem.value === '') {
          if (itemData[x][this.searchSmallItemData.setItemValue] === this.selectSmallItem.value) {
            falg = true;
          }
        } else {
          if (
            itemData[x][this.searchLargeItemData.setItemValue] === this.selectLargeItem.value &&
            itemData[x][this.searchSmallItemData.setItemValue] === this.selectSmallItem.value
          ) {
            falg = true;
          }
        }

        if (falg === true) {
          selectItemArray.push(itemData[x]);
        }
      }
      if (this.search !== '') {
        if (selectItemArray.length == 0) {
          selectItemArray = itemData;
        }
        for (const item of selectItemArray) {
          if (item[this.freeFormatLabelDisplayId].toString().indexOf(this.search) !== -1) {
            this.bodyData.push(item);
          }
        }
      } else {
        this.bodyData = selectItemArray;
      }
    }
  }

  /**
   * 小項目セレクトボタンで選択した際に大項目、小項目内の内容を更新する。
   * @module changeSelectSubOperation
   * @param {string} checkString - 抽出の比較に使用する文言
   * @param {string} selectString - 選択している項目の文言
   * @param {string} selectString - 選択している項目(大項目・小項目)
   */
  private changeSelectSubOperation(checkString: string, selectString: string, selectBox: string) {
    const ArrayData = [];
    const itemData: Array<any> = this.body;
    for (const item of itemData) {
      if (item[checkString] === selectString) {
        ArrayData.push(item);
      }
    }
    if (selectBox == 'small') {
      // 大項目のセレクトボックスデータ取得
      this.largeItem = this.baseModel.setSelectItemArrayFormat(
        ArrayData,
        this.searchLargeItemData.findItemName,
        this.searchLargeItemData.setItemValue,
        this.searchLargeItemData.setItemText
      );
      // 小項目が選択された場合は大項目も絞り込まれているので大項目を表示
      this.selectLargeItem = this.largeItem.options[0];
    } else if (selectBox == 'large') {
      // 小項目のセレクトボックスデータ取得
      this.smallItem = this.baseModel.setSelectItemArrayFormat(
        ArrayData,
        this.searchSmallItemData.findItemName,
        this.searchSmallItemData.setItemValue,
        this.searchSmallItemData.setItemText
      );
    }
  }

  /**
   * 小項目セレクトボタンで選択した際に大項目、小項目内の内容を更新する。
   * @module changeSelectLargeItemOperation
   * @param {Object} data - 子コンポーネントより渡される値
   */
  public changeSelectLargeItemOperation(data: { [k: string]: string }) {
    this.selectLargeItem = data.value;
    this.changeSelectSubOperation(
      this.searchLargeItemData.setItemValue,
      this.selectLargeItem.value,
      'large'
    );
  }

  /**
   * 小項目セレクトボタンで選択した際に大項目、小項目内の内容を更新する。
   * @module changeSelectSmallItemOperation
   * @param {Object} data - 子コンポーネントより渡される値
   */
  public changeSelectSmallItemOperation(data: { [k: string]: string }) {
    this.selectSmallItem = data.value;
    this.changeSelectSubOperation(
      this.searchSmallItemData.setItemValue,
      this.selectSmallItem.value,
      'small'
    );
  }
}
