/*
 * 機能別コンポーネント マスタ参照ダイアログで使用するconst・interface・types
 * ・更新履歴
 *   2021/01/18 ファイル作成 小林
 */

/** マスタ参照ダイアログに表示するテスト用仮ラベル */
export const SearchLabelName = ['検索', '部門名', '部門略称'];
/** マスタ参照ダイアログに表示するボタンラベル */
export const ButtonName = ['検索', 'クリア'];
