































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import Dialog from '@/components/common/modules/Dialog.vue';
import DialogMasterSelectMultiControlTable from '@/components/common/modules/DialogMasterSelectMultiControlTable.vue';
import { DialogStatusResponse } from '@/constants/sample';

@Component({
  components: { InputNoVModel, Label, Dialog, DialogMasterSelectMultiControlTable }
})
export default class MasterSelectMultiSetControl extends Vue {
  // name
  @Prop({ default: '' })
  private name!: string;
  // ラベル名
  @Prop({ default: '' })
  private label?: string;
  // 初期値
  @Prop({ default: '' })
  private value?: any;
  // インプット(左辺)タイプ
  @Prop({ default: 'text' })
  private leftSideInputType?: string;
  // インプット(左辺)ネーム
  @Prop({ default: '' })
  private leftSideInputName!: string;
  // インプット(左辺)内のdefault値
  @Prop({ default: '' })
  private leftSideDefaultValue?: string;
  // インプット(左辺)内の文字数制限
  @Prop({ default: '' })
  private leftSideInputMaxlength?: string;
  // インプット(左辺)の表示・非表示
  @Prop({ default: true })
  private isLeftSideInputCodeVisible?: boolean;
  // インプット(左辺)の操作
  @Prop({ default: false })
  private isLeftSideInputDisabled?: boolean;
  // インプット(左辺)要素プレースホルダ
  @Prop({ default: '' })
  private isLeftSideInputPlaceholder?: string;
  // インプット(左辺)の専用状態
  @Prop({ default: false })
  private isLeftSideInputReadOnly?: boolean;
  // インプット(右辺)タイプ
  @Prop({ default: 'text' })
  private rightSideInputType?: string;
  // インプット(右辺)ネーム
  @Prop({ default: '' })
  private rightSideInputName!: string;
  // インプット(右辺)内のdefault値
  @Prop({ default: '' })
  private rightSideDefaultValue?: string;
  // インプット(右辺)内の文字数制限
  @Prop({ default: '' })
  private rightSideInputMaxlength?: string;
  // インプット(右辺)の表示・非表示
  @Prop({ default: true })
  private isRightSideInputCodeVisible?: boolean;
  // インプット(右辺)の操作
  @Prop({ default: false })
  private isRightSideInputDisabled?: boolean;
  // インプット(右辺)要素プレースホルダ
  @Prop({ default: '' })
  private isRightSideInputPlaceholder?: string;
  // インプット(左辺)の読み取り専用状態
  @Prop({ default: false })
  private isRightSideInputReadOnly?: boolean;
  // ダイヤログ表示のタイトル
  @Prop({ default: '' })
  private dialogTitle?: string;
  // ダイヤログ内自由入力項目の見出し
  @Prop({ default: '' })
  private freeFormatLabelName?: string;
  // ダイヤログ内自由入力項目の検索時に使用するdisplay_Id
  @Prop({ default: '' })
  private freeFormatLabelDisplayId?: string;
  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns!: Array<any>;
  // テーブルに表示するデータ配列
  @Prop({ default: () => [] })
  private body!: Array<any>;
  // ダイアログの表示制御値
  private dialogStatus = false;
  // インプット(左辺)内の値
  private leftSideInputValue = '';
  // インプット(右辺)内の値
  private rightSideInputValue = '';
  // 大項目抽出で使用する検索、設定プロパティの配列
  @Prop({ default: () => ({}) })
  private searchLargeItemData?: any;
  // 小項目抽出で使用する検索、設定プロパティの配列
  @Prop({ default: () => ({}) })
  private searchSmallItemData?: any;
  // テーブルから取得するデータ配列
  @Prop({ default: () => [] })
  private outPutData!: Array<any>;
  // 領域外を押下した際の閉じる処理
  @Prop({ default: false })
  private isPersistent?: boolean;
  // 算出プロパティで使用する変数
  public textValue = '';
  // 取得ボタンの表示・非表示
  @Prop({ default: true })
  private isBtnVisible?: boolean;
  // rulesの付与
  @Prop({ default: '' })
  private rules?: string;
  // 参照マスタダイヤログの大項目、小項目の見出しを変えるのに使用
  @Prop({ default: [] })
  private labelName?: any;
  // 取得ボタン
  @Prop({ default: '検索' })
  private getButtonName?: string;
  @Prop({ default: () => [] })
  private sortKey?: Array<{ [k: string]: string }>;
  @Prop({ default: false })
  private sortDesc?: boolean;

  /**
   * 初期値の設定
   */
  mounted() {
    if (this.value) {
      const target = this.value.i_id ? this.value.i_id : this.value;
      const findData = this.body.find(v => v.i_id === target);
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        if (findData && findData[field.name]) {
          this.outPutData.push({ ...field, ...{ value: findData[field.name] } });
        } else {
          this.outPutData.push({ ...field, ...{ value: '' } });
        }
      });
    } else {
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        this.outPutData.push({ ...field, ...{ value: '' } });
      });
    }
  }

  @Watch('value')
  private onChangeValue() {
    if (this.value) {
      const findData = this.body.find(v => {
        const target = this.value.i_id ? this.value.i_id : this.value;
        return v.i_id === target;
      });
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        if (findData && findData[field.name]) {
          this.outPutData.push({ ...field, ...{ value: findData[field.name] } });
        } else {
          this.outPutData.push({ ...field, ...{ value: '' } });
        }
      });
    } else {
      const setting: Array<any> = JSON.parse(JSON.stringify(this.outPutData));
      this.outPutData.splice(0);
      setting.forEach(field => {
        this.outPutData.push({ ...field, ...{ value: '' } });
      });
    }
  }

  /**
   * インプット(左辺)コンポーネント内でテキストフィールドへの値反映を完結するため、
   * 算出プロパティを設定する（直接propsを変更できないため）
   */
  get computedLeftSideInputValue() {
    return this.textValue;
  }
  set computedLeftSideInputValue(newValue) {
    this.textValue = newValue;
  }

  /**
   * インプット(右辺)コンポーネント内でテキストフィールドへの値反映を完結するため、
   * 算出プロパティを設定する（直接propsを変更できないため）
   */
  get computedRightSideInputValue() {
    return this.textValue;
  }
  set computedRightSideInputValue(newValue) {
    this.textValue = newValue;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return data - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: any) {
    return data;
  }

  /**
   * 入力値をinputにセットする
   * @module catchTableDataRelay
   * @return data - 名前と選択データ
   */
  public catchTableDataRelay(data: any) {
    this.dialogStatus = false;
    this.outPutData.map(v => {
      v['value'] = data.selectData[v.name];
      return v;
    });
    this.inputOperation({ name: this.name, value: data.selectData.i_id });
  }

  /**
   * ダイアログを使用する場合のイベント取得処理
   * 子コンポーネントからEmitされる値をコンソールに出力
   * （値が渡されるか確認用）
   * @module changeDialogStatus
   * @param {Object} e - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  private changeDialogStatus(e: DialogStatusResponse): void {
    this.dialogStatus = false;
  }
}
