




































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DatePicker from '@/components/common/elements/DatePicker.vue';
import { DateTime } from 'luxon';
@Component({
  components: { DatePicker }
})
export default class DateRange extends Vue {
  private minDate = '';
  private maxDate = '';
  // 日付範囲指定Fromフォームの名称
  @Prop({ default: '' })
  private nameFrom!: string;
  // 日付範囲指定Toフォームの名称
  @Prop({ default: '' })
  private nameTo!: string;
  // デートピッカーの表示タイプ初期値
  @Prop({ default: 'date' })
  private type?: string;
  // デートピッカーの表示形式
  @Prop({ default: '' })
  private format?: string;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // アイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: 'mdi-calendar' })
  private icon?: string;
  // デートピッカーを入力可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // デートピッカー内選択ボタン色 (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: 'primary' })
  private sendTextColor?: string;
  // 日付範囲指定Fromフォームの初期値
  @Prop({ default: '' })
  private valueFrom?: string;
  // 日付範囲指定Toフォームの初期値
  @Prop({ default: '' })
  private valueTo?: string;

  /**
   * 親コンポーネントに値を通知する
   * @module selectDateOpratipon
   * @param { [k: string]: string } value
   * @param {string} type 'from' or 'to'
   * @return {[k: string]: string} - input要素名と値
   */
  @Emit('selectDateOpratipon')
  public selectDateOpratipon(value: { [k: string]: string }, type: string) {
    if (type === 'from') {
      this.minDate = value.value;
      value.value = DateTime.fromISO(value.value).startOf('day').toUTC().toString();;
    } else {
      this.maxDate = value.value;
      value.value = DateTime.fromISO(value.value).endOf('day').toUTC().toString();
    }
    return { ...value, ...{ type: 'datetime', range: type } };
  }
}
