















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import Typography from '@/components/common/elements/Typography.vue';
import MultipleLineText from '@/components/common/elements/MultipleLineText.vue';

@Component({
  components: { Label, MultipleLineText, Typography }
})
export default class PopTextArea extends Vue {
  private dialog = false;
  // ボタンラベル
  @Prop({ default: '' })
  private buttonLabel!: string;
  // 追加class
  @Prop({ default: '' })
  private areaClass?: string;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonText?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: '' })
  private buttonColor?: string;

  // 入力要素のname属性
  @Prop({ default: '' })
  private name!: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // 入力要素高さ
  @Prop({ default: '5' })
  private rows?: string;

  /**
   * 親コンポーネントにinput要素名と値を通知する
   * @module inputOperation
   * @param {[k: string]: string} data - input要素名と値
   * @return {[k: string]: string} data - input要素名と値
   */
  @Emit('inputOperation')
  public inputOperation(data: { [k: string]: string }) {
    return data;
  }
}
