





























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import DateRange from '@/components/common/modules/DateRange.vue';

@Component({
  components: { Label, DateRange }
})
export default class DatePickerRangeArea extends Vue {
  // ラベルのname属性
  @Prop({ default: '' })
  private labelName!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private labelText!: string;
  // 追加付与クラス
  @Prop({ default: '' })
  private labelClass?: string;
  // デートピッカー内の選択可能な過去日を指定
  @Prop({ default: '' })
  private minDate?: string;
  // デートピッカー内の選択可能な未来日を指定
  @Prop({ default: '' })
  private maxDate?: string;
  // 日付範囲指定Fromフォームの名称
  @Prop({ default: '' })
  private nameFrom!: string;
  // 日付範囲指定Toフォームの名称
  @Prop({ default: '' })
  private nameTo!: string;
  // 日付範囲指定Fromフォームの初期値
  @Prop({ default: '' })
  private valueFrom?: string;
  // 日付範囲指定Toフォームの初期値
  @Prop({ default: '' })
  private valueTo?: string;
  // デートピッカーの表示タイプ初期値
  @Prop({ default: 'date' })
  private type?: string;
  // デートピッカーの表示形式
  @Prop({ default: 'yyyy-MM-dd' })
  private format?: string;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // アイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: 'mdi-calendar' })
  private icon?: string;
  // デートピッカーを入力可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // デートピッカー内選択ボタン色 (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: 'primary' })
  private sendTextColor?: string;

  /**
   * 親コンポーネントに子コンポーネントの値を通知する
   * @module inputOperation
   * @param {[k: string]: string} data - input要素名と値
   * @return {[k: string]: string} data - input要素名と値
   */
  @Emit('inputOperation')
  public inputOperation(data: { [k: string]: string }) {
    return data;
  }
}
