





























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Input from '@/components/common/elements/Input.vue';
import Radio from '@/components/common/elements/Radio.vue';
import Label from '@/components/common/elements/Label.vue';
import { SelectProps } from '@/constants/form/types';

@Component({
  components: { Input, Radio, Label }
})
export default class RadioArea extends Vue {
  // name属性
  @Prop({ default: '' })
  private name!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private label!: string;
  // 初期選択value
  @Prop({ default: '' })
  private value?: string;
  // 読み取り専用か
  @Prop({ default: false })
  public isReadonly?: boolean;
  // ラジオデータ
  @Prop({ default: () => ({ name: '', options: [] }) })
  private selectData!: SelectProps;
  // 入力チェック
  @Prop({ default: '' })
  private rules?: string;

  private type = 'radio';

  @Watch('value')
  watchVal() {
    const val = this.value === undefined ? '' : this.value;
    this.inputOperation({ name: this.name, value: val, type: this.type });
  }

  @Emit('inputOperation')
  public inputOperation(value: { [k: string]: string }) {
    value.type = this.type;
    return value;
  }
}
