

















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { buttonLabel, tableColumn } from '@/constants/form/form';
import { HexaFileFieldResponse } from '@/services/hexabase/types';
import CheckBoxTable from '@/components/common/modules/CheckBoxTable.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import Button from '@/components/common/elements/Button.vue';

@Component({
  components: { CheckBoxTable, SingleText, Button }
})
export default class FileManagerForm extends Vue {
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 現時点で格納されているファイル
  @Prop({ default: () => [] })
  private currentFiles?: HexaFileFieldResponse[];
  // 入力ルール
  @Prop({ default: '' })
  private rules?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: 'ファイル選択' })
  private placeholder?: string;
  // アップロードされたファイル
  private inputFiles: File[] = [];
  // テーブルで選択されたファイル
  private selectItem: any[] = [];

  // テーブル内で使用するボタン定義（外部指定）
  @Prop({ default: () => [] })
  private buttonItems?: { [k: string]: string | number | boolean }[];
  // テーブルカラム定義（外部指定）
  @Prop({ default: () => [] })
  private columnsItems?: { [k: string]: string | number | boolean }[];
  @Prop({ default: () => [] })
  private imageItems?: { [k: string]: string | number | boolean }[];

  // テーブル内ボタン定義
  private buttons: { [k: string]: string | number | boolean }[] = [];
  // テーブルカラム定義
  private columns: { [k: string]: string | number | boolean }[] = [];
  // ファイルアップロード後にフィールド値が初期化されない為、Click時Vmodelにて初期化
  private upfiles = [];

  mounted() {
    // 外部からテーブルカラム・ボタン定義があった場合上書き
    if (this.buttonItems !== undefined && this.buttonItems.length > 0) {
      this.buttons = this.buttonItems;
    } else {
      this.buttons = this.changeDisable();
    }
    if (this.columnsItems !== undefined && this.columnsItems.length > 0) {
      this.columns = this.columnsItems;
    } else {
      this.columns = this.getTableColumn('columns');
    }
  }

  // disableの切り替え監視
  // 切り替わりタイミングにてテーブル内ボタンを活性にする
  @Watch('isDisabled')
  watchIsDisabled() {
    this.buttons = this.changeDisable();
  }

  /**
   * テーブル内ボタンの入力（押下）可否の切り替え
   */
  private changeDisable(): { [k: string]: string | number | boolean }[] {
    return this.getTableColumn('buttonItems').map((v: { [k: string]: string | boolean }) => {
      if (v.disabled !== undefined && typeof v.disabled === 'boolean') {
        v.disabled = this.isDisabled ? this.isDisabled : false;
      }
      return v;
    });
  }

  // テーブルで選択されたファイル情報を取得
  private selectItemHandle(data: any): void {
    this.selectItem = data;
  }

  // ボタン定義を取得
  private getButtonLabel(str: string) {
    return buttonLabel[str];
  }

  // テーブルカラム定義を取得
  private getTableColumn(str: string) {
    return tableColumn[str] === undefined ? [] : tableColumn[str];
  }

  // 既存ファイル個別削除アイコン押下時処理
  private clickDeleteIcon(data: any): void {
    this.buttonAction('delete', data.record);
  }

  /**
   * 親コンポーネントに既存ファイルに対しての処理を通知
   * @param {string} value - 処理名
   * @param {HexaFileFieldResponse} - 対象ファイル指定（個別削除アイコン押下時）
   * @returns {{name:string, select: HexaFileFieldResponse[]}}
   */
  @Emit('buttonAction')
  private buttonAction(value: string, deleteFile?: HexaFileFieldResponse) {
    return {
      name: value,
      select: value === 'delete' && deleteFile !== undefined ? [deleteFile] : this.selectItem
    };
  }

  /**
   * ファイルアップロードを親コンポーネントに通知
   * @param {File[]} getFile - アップロードファイル
   * @returns {File[]}
   */
  @Emit('getInputFile')
  public getInputFile(getFile: File[]): File[] {
    this.inputFiles = getFile;
    return this.inputFiles;
  }
}
