





































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import Input from '@/components/common/elements/Input.vue';

@Component({
  components: { Label, Input }
})
export default class InputArea extends Vue {
  // ラベルのname属性
  @Prop({ default: '' })
  private labelName!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private labelText!: string;
  // 追加付与クラス
  @Prop({ default: '' })
  private labelClass?: string;
  // 要素タイプ
  @Prop({ default: 'text' })
  private inputType?: string;
  // 入力要素名
  @Prop({ default: '' })
  private name!: string;
  // 入力要素初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素最大入力数
  @Prop({ default: '' })
  private maxlength?: string;
  // 入力要素がアクティブか否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // アイコンの付与
  @Prop({ default: '' })
  private appendIcon?: string;
  // ladelClass
  @Prop({ default: '' })
  private labelDivClass?: string;
  // 入力要素最大入力数
  @Prop({ default: '' })
  private inputDivClass?: string;
  // readonly
  @Prop({ default: false })
  private isReadonly?: boolean;
  // アイコンを入力エリア前に付与する
  @Prop({ default: '' })
  private prependIcon?: string;
  // アイコンを入力エリア内に付与する
  @Prop({ default: '' })
  private prependInnerIcon?: string;
  // バリデーションルール
  @Prop({ default: '' })
  private rules?: string;
  // 最大値
  @Prop({ default: null })
  private max?: number;
  // 最小値
  @Prop({ default: null })
  private min?: number;

  @Watch('value')
  watchVal() {
    const val = this.value === undefined ? '' : this.value;
    this.inputOperation({ name: this.name, value: val, type: this.inputType });
  }

  /**
   * 親コンポーネントに子コンポーネントの値を通知する
   * @module inputOperation
   * @param {[k: string]: string} data- input要素名と値
   * @return {[k: string]: string} data - input要素名と値
   */
  @Emit('inputOperation')
  public inputOperation(data: { name: string; value: string; type?: string }) {
    data.type = this.inputType;
    return data;
  }
}
