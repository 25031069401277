














































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import SelectNoVModel from '@/components/common/elements/SelectNoVModel.vue';
import { InputResponse } from '@/constants/form/types';

@Component({
  components: { InputNoVModel, SelectNoVModel, Label }
})
export default class MasterSelectComboBox extends Vue {
  // ラベルのname属性
  @Prop({ default: '' })
  private labelName?: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private labelText?: string;
  // インプットタイプ
  @Prop({ default: 'text' })
  private type?: string;
  // インプットネーム
  @Prop({ default: '' })
  private name!: string;
  // インプット内の文字数制限
  @Prop({ default: '' })
  private maxlength?: string;
  // インプットの表示・非表示
  @Prop({ default: true })
  private isCodeVisible?: boolean;
  // インプットの操作
  @Prop({ default: false })
  private isInputDisabled?: boolean;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // セレクト内で使用するデータ
  @Prop({ default: () => ({ name: '', options: [] }) })
  private selectData!: any;
  @Prop({ default: () => ({}) })
  public selectDefaultValue?: { [k: string]: string };
  // セレクトの操作
  @Prop({ default: false })
  private isSelectDisabled?: boolean;
  // インプット内の値
  @Prop({ default: '' })
  InputValue?: string;
  // セレクト内選択データ
  selectValue = {};
  // テキストフィールドデータ
  textValue = '';

  /**
   * 初期値の設定
   */
  mounted() {
    if (this.selectDefaultValue) {
      this.selectValue = this.selectDefaultValue;
    }
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return data - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: InputResponse) {
    if (data.value !== '') {
      const findData = this.selectData.options.find((v: any) => v.value === data.value);
      if (findData !== undefined) {
        this.selectValue = findData;
      } else {
        this.selectValue = {};
      }
    } else {
      this.selectValue = {};
    }
    return data;
  }

  /**
   * 親コンポーネントに選択値を通知する
   * @module changeSelectOperationRelay
   * @return data - 選択した項目内容
   */
  @Emit('changeSelectOperation')
  public changeSelectOperation(data: { [k: string]: any }) {
    this.computedValue = data.value.value;
    this.inputOperation({ name: this.name, value: data.value.value, type: 'select' });
    return data.value;
  }

  /**
   * コンポーネント内でテキストフィールドへの値反映を完結するため、
   * 算出プロパティを設定する（直接propsを変更できないため）
   */
  get computedValue() {
    return this.textValue;
  }
  set computedValue(newValue) {
    this.textValue = newValue;
  }
}
