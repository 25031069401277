














import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import Icon from '@/components/common/elements/Icon.vue';
import Button from '@/components/common/elements/Button.vue';
import IconTextLink from '@/components/common/elements/IconTextLink.vue';

@Component({
  components: { Label, Icon, Button, IconTextLink }
})
export default class PageInfoBar extends Vue {
  // アイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: 'mdi-checkbox-blank-circle' })
  private icon?: string;
  // ルーター設定からページ情報を格納する変数
  private items: any = [];
  private title?: string = '';

  // ルーター設定からページ情報を格納する
  mounted() {
    if (
      this &&
      this.$route &&
      this.$route.meta &&
      this.$route.meta.breadcrumbs &&
      this.$route.meta.title &&
      this.$route.matched.some(obj => obj.meta.breadcrumbs)
    ) {
      this.items = this.$route.meta.breadcrumbs;
      this.title = this.$route.meta.title;
    }
    return;
  }
}
