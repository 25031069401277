









































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { InputResponse } from '@/constants/form/types';

@Component({})
export default class InputNoVModel extends Vue {
  // 要素タイプ
  @Prop({ default: 'text' })
  private type?: string;
  // 入力要素名
  @Prop({ default: '' })
  private name!: string;
  // 入力要素初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素の背景色
  @Prop({ default: '' })
  private backgroundColor?: string;
  // 入力要素名
  @Prop({ default: '' })
  private label?: string;
  // 入力要素プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素最大入力数
  @Prop({ default: '' })
  private maxlength?: string;
  // 入力要素がアクティブか否か
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用
  @Prop({ default: false })
  private isReadOnly?: boolean;
  // アイコンの付与
  @Prop({ default: '' })
  private appendIcon?: string;

  @Prop({ default: '' })
  private prependIcon?: string;
  // アイコンを入力エリア内に付与する
  @Prop({ default: '' })
  private prependInnerIcon?: string;
  // クリア
  @Prop({ default: false })
  private isClearable?: boolean;
  // rulesの付与
  @Prop({ default: '' })
  private rules?: string;

  public inputVal = '';
  public errMessage = '';

  mounted() {
    if (this.value) {
      this.inputVal = this.value;
    }
  }

  @Watch('value')
  private onChangeValue() {
    if (this.value) {
      this.inputVal = this.value;
    }
  }

  private get inputValue(): string {
    return this.inputVal;
  }

  private set inputValue(inputVal: string) {
    this.inputVal = inputVal;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return InputResponse - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: string): InputResponse {
    return { name: this.name, value: data };
  }

  /**
   * マスタセレクト関連コンポーネントにて
   * エラーを左入力フィールドに通知する為
   * エラーを親に返却
   */
  @Emit('getErr')
  getErr(errors: any) {
    return errors;
  }
}
