





























import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { FileFormResponse } from '@/constants/form/types';

import InputArea from '@/components/common/modules/InputArea.vue';
import SelectArea from '@/components/common/modules/SelectArea.vue';
import TextArea from '@/components/common/modules/TextArea.vue';
import DatePickerArea from '@/components/common/modules/DatePickerArea.vue';
import DatePickerRangeArea from '@/components/common/modules/DatePickerRangeArea.vue';
import MasterSelectComboBox from '@/components/common/modules/MasterSelectComboBox.vue';
import MasterSelectControl from '@/components/common/modules/MasterSelectControl.vue';
import MasterSelectSetControl from '@/components/common/modules/MasterSelectSetControl.vue';
import MasterSelectMultiSetControl from '@/components/common/modules/MasterSelectMultiSetControl.vue';
import PopTextArea from '@/components/common/modules/PopTextArea.vue';
import SelectComboBoxArea from '@/components/common/modules/SelectComboBoxArea.vue';
import RadioArea from '@/components/common/modules/RadioArea.vue';
import PopImagePreView from '@/components/common/modules/PopImagePreView.vue';
import FileManager from '@/components/common/modules/FileManager.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import SelectNoVModel from '@/components/common/elements/SelectNoVModel.vue';
import StatusBox from '@/components/common/modules/StatusBox.vue';

interface CreateComponentProps {
  [k: string]: any;
}
@Component({
  components: {
    InputArea,
    SelectArea,
    TextArea,
    DatePickerArea,
    DatePickerRangeArea,
    MasterSelectComboBox,
    MasterSelectControl,
    MasterSelectSetControl,
    MasterSelectMultiSetControl,
    PopTextArea,
    SelectComboBoxArea,
    RadioArea,
    PopImagePreView,
    FileManager,
    InputNoVModel,
    SelectNoVModel,
    StatusBox
  }
})
export default class AutoCreateForm extends Vue {
  @Prop({ default: () => [] })
  private fieldData!: Array<{ [k: string]: any }>;
  @Prop({ default: () => [] })
  private fieldCols!: Array<{ [k: string]: any }>;
  @Prop({ default: '' })
  private addClass?: string;

  @Emit('catchInput')
  private catchInput(data: any) {
    return data;
  }

  @Emit('emitFileData')
  private emitFileData(data: FileFormResponse): FileFormResponse {
    return data;
  }

  @Watch('fieldData')
  private watchFieldData(fieldData: Array<{ [k: string]: any }>) {
    // 表示項目をフィルタする
    const showField = fieldData.filter(field => field.show === true);
    // 表示項目のデータが持つ行番号を控える
    const rowGroupArray = [];
    for (const key in showField) {
      rowGroupArray.push(showField[key].row);
    }
    // 表示項目が持つ行番号（重複削除）から、行番号単位の項目グループオブジェクトを生成
    const rowGroupRecord: Array<any> = [];
    Array.from(new Set(rowGroupArray)).forEach(function(element, index) {
      rowGroupRecord[index] = showField.filter(field => field.row === element);
    });
    for (const row in rowGroupRecord) {
      // col順でソートする
      rowGroupRecord[row].sort(function(a: any, b: any) {
        return a.col - b.col;
      });
      let previousCol = 0;
      let previousSizeX = 0;
      for (const key in rowGroupRecord[row]) {
        // 前項目のcol+sizeXの値が現在のcolと等しくない場合は、col+sizeXの値に変更する
        if (previousCol + previousSizeX !== rowGroupRecord[row][key].col) {
          rowGroupRecord[row][key].col = previousCol + previousSizeX;
        }
        previousCol = rowGroupRecord[row][key].col;
        previousSizeX = rowGroupRecord[row][key].sizeX;
      }
    }
  }
}
