

































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Table from '@/components/common/elements/Table.vue';

@Component({
  components: { Table }
})
export default class CheckBoxTable extends Vue {
  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns?: Array<{ [k: string]: string }>;
  // テーブルに表示するデータ配列
  @Prop({ default: () => [] })
  private body?: Array<{ [k: string]: string }>;
  // 表示アイテム数
  @Prop({ default: 100 })
  private perPage?: number;
  // チェックボックス項目の表示制御
  @Prop({ default: false })
  private showSelect?: boolean;
  // テーブル内項目のchip制御
  @Prop({ default: () => [] })
  private chipItems?: Array<{ [k: string]: string }>;
  // テーブル内項目のchip制御
  @Prop({ default: () => [] })
  private buttonItems?: Array<{ [k: string]: string }>;
  // テーブル内項目のchip制御
  @Prop({ default: () => [] })
  private inputItems?: Array<{ [k: string]: string }>;
  // テーブル内項目の画像制御
  @Prop({ default: () => [] })
  private imageItems?: Array<{ [k: string]: string }>;

  @Prop({ default: '' })
  private addClass?: string;

  @Prop({ default: '' })
  private itemKey?: string;

  @Prop()
  private customFilter?: Function;

  @Prop()
  private customSort?: Function;

  // テーブル内テキスト
  @Prop({ default: '' })
  private tableText?: string;

  /**
   * 親コンポーネントに押下した行を通知する
   * @module clickRowOperation
   * @param  {any} - 対象行データ
   * @return { [k: string]: string } - 押下された行データ
   */
  @Emit('clickRowOperation')
  public clickRowOperation(clickRecord: any): { [k: string]: string } {
    return clickRecord;
  }
  /**
   *  親コンポーネントに選択されているアイテムを配列で返します
   * @module itemSelected
   * @param Array<{[k: string]: string }>
   * @return Array<{[k: string]: string }>
   */
  @Emit('itemSelected')
  public itemSelected(list: Array<{ [k: string]: string }>) {
    return list;
  }
  /**
   * 親コンポーネントにボタンが押された行のアイテムをオブジェクトで返します
   * @module clickButton
   * @param Array<{[k: string]: string }>
   * @return Array<{[k: string]: string }>
   */
  @Emit('clickButton')
  public clickButton(record: Array<{ [k: string]: string }>) {
    return record;
  }
  /**
   * 親コンポーネントに入力情報を通知する
   * @module getData
   * @param  {response}} - { inputValue, inputName, itemIndex }
   * @return {response}} - { inputValue, inputName, itemIndex }
   */
  @Emit('getData')
  public getData(response: any) {
    return response;
  }

  /**
   * 親コンポーネントにエラーを通知する
   * @module checkErr
   * @param  {error}} - string | null
   * @return string | null
   */
  @Emit('checkErr')
  checkErr(error: { [k: string]: string }): { [k: string]: string } {
    return error;
  }
}
