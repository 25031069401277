

























































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import PopUpModal from '@/components/common/modules/PopUpModal.vue';
import FileManagerForm from '@/components/form/FileManagerForm.vue';
import Dialog from '@/components/common/modules/Dialog.vue';
import Button from '@/components/common/elements/Button.vue';
import SingleText from '@/components/common/elements/SingleText.vue';

import FileClass from '@/models/lib/file';
import { FileFormResponse } from '@/constants/form/types';
import { HexaFileFieldResponse } from '@/services/hexabase/types';

@Component({
  components: { PopUpModal, FileManagerForm, Dialog, Button, SingleText }
})
export default class FileManager extends Vue {
  private File = new FileClass();

  private dialog = false;

  // モーダル仕様か
  @Prop({ default: false })
  private isModal?: boolean;

  // 一括処理仕様か
  @Prop({ default: false })
  private isBulk?: boolean;

  // 一括処理モーダル
  @Prop({ default: false })
  private isBulkDialog?: boolean;

  // 一括処理モーダルボタン制御
  @Prop({ default: false })
  private bulkFileUpCloseDisable?: boolean;

  // 一括処理モーダルボタン制御
  @Prop({ default: '' })
  private bulkFileUpButtonLabel?: string;

  // MAXモーダル幅
  @Prop({ default: '1000' })
  private modalMaxWidth?: string;

  // ボタンラベル
  @Prop({ default: '' })
  private buttonLabel!: string;
  // ダイアログ内ボタン文字色
  @Prop({ default: '' })
  private buttonColor?: string;
  // 追加class
  @Prop({ default: '' })
  private areaClass?: string;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内OKボタン表示制御
  @Prop({ default: true })
  private isSubmit?: boolean;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonSubmitText?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: '' })
  private buttonSubmitColor?: string;
  // ダイアログ内Cancelボタン表示制御
  @Prop({ default: true })
  private isCancel?: boolean;
  // ダイアログ内Cancelボタン文字
  @Prop({ default: 'キャンセル' })
  private buttonCancelText?: string;
  // ダイアログ内Cancelボタン文字色
  @Prop({ default: '' })
  private buttonCancelColor?: string;
  // モーダル注意書き
  @Prop({ default: '' })
  private modalCaution?: string;

  // 入力要素のname属性
  @Prop({ default: '' })
  private name!: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;

  // アプリid
  @Prop({ default: '' })
  private appId!: string;
  // データベースid
  @Prop({ default: '' })
  private dsId!: string;
  // アイテムid
  @Prop({ default: '' })
  private itemId?: string;
  // フィールドid
  @Prop({ default: '' })
  private fieldId!: string;
  // アクションid
  @Prop({ default: 'update' })
  private actionName!: string;
  // ファイルアップロード上限数
  @Prop({ default: 3 })
  private maximum!: boolean;
  // 入力ルール
  @Prop({ default: '' })
  private rules?: string;

  // テーブル内で使用するボタン定義（外部指定）
  @Prop({ default: () => [] })
  private buttonItems?: { [k: string]: string | number | boolean }[];
  // テーブルカラム定義（外部指定）
  @Prop({ default: () => [] })
  private columnsItems?: { [k: string]: string | number | boolean }[];
  @Prop({ default: () => [] })
  private imageItems?: { [k: string]: string | number | boolean }[];

  // 実行通知関連
  private isExecDialog = false;
  private execDialogTitle = '実行確認';
  private execDialogMessage = '';
  // プログレス関連
  private isLoading = false;
  private isCancelDisable = false;
  private isSubmitDisable = false;
  // アラート表示関連
  private isAlert = false;
  private alertMessage = '';
  private alertStyle = '';
  // ファイル情報周り
  private inputFiles: File[] = [];
  private currentFiles: HexaFileFieldResponse[] = [];
  private revNo = 0;
  private localModifyRules = '';
  private localFileCountRule = '';
  private count = 0;
  // アイテム情報
  private item = '';
  // アプリ周り
  private actionId = '';
  private app = '';
  private ds = '';

  async mounted() {
    this.app = this.appId;
    this.ds = this.dsId;
    this.item = this.itemId || '';
    this.actionId = this.actionName;
    this.localModifyRules = this.rules || '';
    if (typeof this.app == 'string' && typeof this.ds == 'string' && this.item) {
      await this.getFiles();
    }
  }

  @Watch('dsId')
  watchDsId() {
    this.ds = this.dsId;
  }

  // アイテムID更新時再度データを取得
  @Watch('itemId')
  async watchitemId() {
    this.item = this.itemId || '';
    this.setCurrentFiles([]);
    this.setFileCount(this.currentFiles);
    this.clearInputFiles();
    await this.getFiles();
  }

  // マウント後入力ルールが更新された際、入力ルールをセット
  @Watch('rules')
  watchRules() {
    this.setLocalFileCountRule(this.File.getCountRule(this.currentFiles, this.rules));
    this.setLocalModifyRules(this.File.modifyRule(this.currentFiles, this.rules));
  }

  /**
   * DBよりファイル情報を取得
   * @returns {void}
   */
  private async getFiles(): Promise<void> {
    try {
      this.emitIsLoading(true);
      this.setLoading(true);
      const result = await this.File.getFileList(this.app, this.ds, this.item, this.fieldId);
      this.setRevNum(result.rev);
      this.setCurrentFiles(result.files);
      this.setLocalModifyRules(this.File.modifyRule(this.currentFiles, this.rules));
      this.setLocalFileCountRule(this.File.getCountRule(this.currentFiles, this.rules));
    } catch (e) {
      this.setCurrentFiles([]);
    } finally {
      this.setFileCount(this.currentFiles);
      this.setLoading(false);
      this.emitIsLoading(false);
    }
  }

  /**
   * 既存ファイルに対する処理
   * @param {{name: string, select: {[k:string]: string}[]}} - イベント返り値
   * @returns {void}
   */
  private async listAction(input: {
    name: string;
    select: { [k: string]: string }[];
  }): Promise<void> {
    this.clearAlert();

    if (input.name === 'download') {
      await this.File.downLoadFiles(input.select);
    }

    if (input.name === 'delete') {
      try {
        this.emitIsLoading(true);
        this.setLoading(true);
        await this.File.deleteFiles(
          this.app,
          this.ds,
          this.item,
          this.actionId,
          this.fieldId,
          this.revNo,
          input.select,
          this.currentFiles
        );
        await this.getFiles();
        this.setAlert(true, this.File.getDeleteMessage(true), 'success');
      } catch (err) {
        this.setAlert(true, this.File.getDeleteMessage(false), 'error');
      } finally {
        this.setLoading(false);
        this.emitIsLoading(false);
        this.emitFileData();
      }
    }
  }

  /**
   * 「実行ボタン」「キャンセルボタン」押下時処理
   * @param {bool} - 実行かキャンセルか
   * @returns {void}
   */
  public async catchConfirm(isAgree: boolean, isModal?: boolean): Promise<void> {
    // 実行押下時処理
    if (isAgree) {
      try {
        this.emitIsLoading(true);
        if (isModal) this.$store.commit('hexabase/setIsLoading', true);
        if (!isModal) this.setLoading(true);

        let upfile = await this.uploadFile();
        const current = this.currentFiles.map(v => v.file_id);
        upfile = [...current, ...upfile];

        await this.File.execAction(
          this.app,
          this.ds,
          this.item,
          this.actionId,
          this.revNo,
          upfile,
          this.fieldId
        );
        await this.getFiles();

        this.setExecDialog(this.File.getUploadMessage(true));
      } catch (err) {
        this.setExecDialog(this.File.getUploadMessage(false));
      } finally {
        this.clearInputFiles();
        if (isModal) this.$store.commit('hexabase/setIsLoading', false);
        if (!isModal) this.setLoading(false);
        this.emitIsLoading(false);
        this.emitFileData();
      }
    }
    this.clearAlert();
  }

  /**
   * 親コンポーネントにinput要素名と値を通知する
   * 一括処理の場合は親コンポーネントでダイアログを閉じる
   * @module onConfirm
   * @param {bool} Agree - true false
   * @return {bool}
   */
  @Emit('onBulkFileUpConfirm')
  public onBulkFileUpConfirm() {
    return this.isBulkDialog;
  }

  /**
   * ファイルアップロード処理
   */
  private async uploadFile() {
    this.clearAlert();
    try {
      return await this.File.uploadFiles(
        this.app,
        this.ds,
        this.item,
        this.fieldId,
        this.inputFiles
      );
    } catch (e) {
      return [];
    }
  }

  // 現状のアイテムリビジョンとファイルidを返却
  @Emit('emitFileData')
  public emitFileData(): FileFormResponse {
    return {
      revNumber: this.revNo,
      itemId: this.item,
      item: { [this.fieldId]: this.currentFiles.map(v => v.file_id) }
    };
  }

  // 処理中を親コンポーネントに通知
  @Emit('emitIsLoading')
  public emitIsLoading(isLoading: boolean): boolean {
    return isLoading;
  }

  // 更新分リビジョン番号セット
  private setRevNum(value: number): void {
    this.revNo = value;
  }

  // hexabaseフィールドに登録されているファイル情報セット
  private setCurrentFiles(list: HexaFileFieldResponse[]): void {
    this.currentFiles = list;
  }

  // 新たに添付フィールドにUPされたファイル情報セット
  private setInputFiles(files: File[]): void {
    this.inputFiles = files;
  }

  // 添付フィールドにUPされたファイル情報クリア
  private clearInputFiles(): void {
    this.inputFiles.splice(0);
  }

  // 入力ルール更新
  private setLocalModifyRules(str: string): void {
    this.localModifyRules = str;
  }

  // ファイル数入力ルール更新
  private setLocalFileCountRule(str: string): void {
    this.localFileCountRule = str;
  }

  // 実行ボタン制御
  private setSubmitDisable(invalid: boolean, errors: { [x: string]: string[] }): boolean {
    let obj: string[] = [];
    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        obj = [...obj, key];
      }
    }
    let passFlg = false;
    if (obj.length === 1 && obj[0] === 'count') {
      passFlg = true;
    }

    if (this.inputFiles.length === 0) return true;
    if (passFlg) return false;
    return invalid;
  }

  // 実行確認ダイアログセット
  private setExecDialog(message: string): void {
    this.isExecDialog = true;
    this.execDialogMessage = message;
  }

  // 実行確認ダイアログ初期化
  private clearExecDialog(): void {
    this.isExecDialog = false;
    this.execDialogMessage = '';
  }

  // プログレスセット
  private setLoading(flg: boolean): void {
    this.isLoading = flg;
    this.isCancelDisable = flg;
    this.isSubmitDisable = flg;
  }

  // アラート情報セット
  private setAlert(flg: boolean, message: string, style: string): void {
    this.isAlert = flg;
    this.alertMessage = message;
    this.alertStyle = style;
  }

  // アラート関連初期化
  private clearAlert(): void {
    this.isAlert = false;
    this.alertMessage = '';
    this.alertStyle = '';
  }

  // ファイルカウントセット
  private setFileCount(files: HexaFileFieldResponse[]): void {
    this.count = files.length;
  }
}
