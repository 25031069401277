




















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class Button extends Vue {
  // ボタンテキスト
  @Prop({ default: '' })
  private value!: string;
  // ボタンカラー (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: '' })
  private color?: string;
  // ボタン name
  @Prop({ default: '' })
  private name?: string;
  // テキストのボタンか
  @Prop({ default: false })
  private isText?: boolean;
  // ボタン 押下可能か否か
  @Prop({ default: false })
  private isDisabled?: boolean;

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module isPushButton
   * @return {boolean} - 押下したか否か
   */
  @Emit('isPushButton')
  public isPushButton(): boolean {
    this.valEmit();
    return true;
  }

  /**
   * 親コンポーネントに押下したボタンのnameを伝える
   * @module nameEmit
   * @return {boolean} - 押下したか否か
   */
  @Emit('valEmit')
  public valEmit(): string {
    return this.name || '';
  }
}
