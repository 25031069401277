

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class IconTextLink extends Vue {
  // アイコン色 (参考：https://vuetifyjs.com/ja/styles/colors/#sass-)
  @Prop({ default: '' })
  private color?: string;
  // アイコン種類 (参考：https://materialdesignicons.com/)
  @Prop({ default: '' })
  private icon!: string;
  // アイコンサイズ
  @Prop({ default: '' })
  private size?: string;
  // アイコンのイベント制御
  @Prop({ default: false })
  private isDisabled?: boolean;
  // テキスト
  @Prop({ default: false })
  private text?: string;
  // class
  @Prop({ default: false })
  private addClass?: string;

  private iconSize: { [k: string]: boolean } = {
    xSmall: false,
    small: false,
    medium: false,
    large: false,
    xLarge: false
  };

  mounted() {
    if (this.size?.length && this.size in this.iconSize) {
      this.iconSize[this.size] = true;
    }
  }

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module clickOperation
   * @return {boolean} - 押下したか否か
   */
  @Emit('clickOperation')
  public clickOperation(): boolean {
    return true;
  }
}
