



































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Typography from '@/components/common/elements/Typography.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import Table from '@/components/common/elements/Table.vue';
import { TableColumn } from '@/constants/components/table';
import { InputResponse } from '@/constants/form/types';
import { SearchLabelName, ButtonName } from '@/constants/components/dialogMasterSelectTable';

@Component({
  components: { SingleText, Typography, Table, InputNoVModel }
})
export default class DialogMasterSelectTable extends Vue {
  // ダイアログ開閉状態
  @Prop({ default: false })
  private dialog?: boolean;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログ内表示テキスト
  @Prop({ default: '' })
  private bodyText!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内NGボタン文字
  @Prop({ default: '閉じる' })
  private buttonTextClose?: string;
  // ダイアログ内NGボタン文字色
  @Prop({ default: 'light' })
  private buttonColorNG?: string;
  // ダイヤログの表示切替パラメータ
  @Prop({ default: 'A' })
  private viewParam?: string;
  // 表示アイテム数
  @Prop({ default: -1 })
  private perPage?: number;

  // テーブルに表示するカラム配列
  @Prop({ default: [] })
  private columns!: Array<TableColumn>;
  // テーブルに表示するデータ配列
  @Prop({ default: [] })
  private body!: any;

  // 検索文字列
  private search = '';
  // 画面内で表示するデータ配列
  private bodyData: Array<string> = [];
  // 画面内で表示するラベル配列
  private searchLabelName = SearchLabelName;
  // 画面内で表示するボタン配列
  private buttonName = ButtonName;

  // TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
  //private searchArray:Array<string> = [];

  /**
   * 初期値の設定
   */
  mounted() {
    if (this.viewParam === 'A') {
      this.bodyData = this.body;
    }
    /* TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
    else if (this.viewParam === 'B') {
      this.searchArray = []
      for(let index = 0; index < this.columns.length; index++) {
        this.searchArray[index] = '';
      }
    }
    */
  }

  @Watch('body')
  watchValue() {
    if (this.viewParam === 'A') {
      this.bodyData = this.body;
    }
  }

  // ダイヤログ検索のカスタムフィルター
  customFilter(items: any, search: any, item: any) {
    return (
      items !== null &&
      search !== null &&
      typeof items === 'string' &&
      items.toString().indexOf(search) !== -1
    );
  }

  /**
   * 親コンポーネントに押下したTableの内容を通知する
   * @module catchInput
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  @Emit('catchTableData')
  private catchTableData(response: any) {
    return { dialog: false, selectData: response };
  }

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module addDetailsOpratipon
   * @return {AddDetailResponse} - 要素ステータス、押下判定
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(isAgree: boolean): { [k: string]: boolean } {
    this.search = '';
    /* TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
    if (this.viewParam === 'B') {
      const dummy = []
      for(let index = 0; index < this.searchArray.length; index++)
      {
        dummy.push('') 
      }
      this.searchArray = dummy
      this.bodyData = [];
    }
    */
    return { dialog: false, isAgree: isAgree };
  }

  /**
   * 入力値の更新と親コンポーネントに入力値を通知
   * @module inputOperationRelay
   * @return data - 名前と入力値
   */
  @Emit('inputOperationRelay')
  public inputOperationRelay(data: InputResponse) {
    if (this.viewParam === 'A') {
      if ('name1' === data.name) {
        this.search = data.value;
      }
    }
    /* TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
    } else if (this.viewParam === 'B') {
      this.searchArray[Number(data.name)] = data.value
    }
    */
  }

  /**
   * 「検索」ボタン押下時の検索処理
   * @module inputSearchOperation
   */
  public inputSearchOperation() {
    /* TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
    if(this.viewParam === 'B') {
      this.bodyData = [];
      for(let x = 0; x < this.body.length; x++) {        
        let falg = true;
        for(let y = 0; y < this.searchArray.length; y++) {
          if(this.body[x][String(this.columns[y].value)].toString().indexOf(this.searchArray[y]) === -1) {
            falg = false;
          }
        }
        if(falg === true) {
          this.bodyData.push(this.body[x]);
        }
      }
    }
    */
  }

  /**
   * 「クリア」ボタンを押下した際にInput内の検索文言を削除する。
   * @module inputDeleteOperation
   */
  public inputDeleteOperation() {
    this.search = '';
    /* TODO:vuetifyの標準検索を使用するが機能拡張で使用する可能性があるのでコメントアウト
    if (this.viewParam === 'B') {
      for(let index = 0; index < this.columns.length; index++) {
        this.searchArray.splice(index, 0, '')
        this.searchArray.pop()
      }
      this.bodyData = [];
    }
    */
  }
}
