












































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import InputNoVModel from '@/components/common/elements/InputNoVModel.vue';
import Dialog from '@/components/common/modules/Dialog.vue';
import DialogMasterSelectTable from '@/components/common/modules/DialogMasterSelectTable.vue';
import { DialogStatusResponse } from '@/constants/sample';

@Component({
  components: { InputNoVModel, Label, Dialog, DialogMasterSelectTable }
})
export default class MasterSelectControl extends Vue {
  // ラベル名
  @Prop({ default: '' })
  private label?: string;
  // インプット(左辺)タイプ
  @Prop({ default: 'text' })
  private leftSideInputType?: string;
  // インプット(左辺)ネーム
  // form.tsやsearch.tsで本component使用時にprops.leftSideInputNameで
  // 指定したdisplay_idの値が左辺に表示されるようになる。
  @Prop({ default: '' })
  private leftSideInputName!: string;
  // インプット(左辺)内のdefault値
  @Prop({ default: '' })
  private value?: any;
  // インプット(左辺)内の文字数制限
  @Prop({ default: '' })
  private leftSideInputMaxlength?: string;
  // インプット(左辺)の表示・非表示
  @Prop({ default: true })
  private isLeftSideInputCodeVisible?: boolean;
  // インプット(左辺)の操作
  @Prop({ default: false })
  private isLeftSideInputDisabled?: boolean;
  // インプット(左辺)要素プレースホルダ
  @Prop({ default: '' })
  private isLeftSideInputPlaceholder?: string;
  // インプット(左辺)の専用状態
  @Prop({ default: false })
  private isLeftSideInputReadOnly?: boolean;
  // インプット(右辺)タイプ
  @Prop({ default: 'text' })
  private rightSideInputType?: string;
  // インプット(右辺)ネーム
  // form.tsやsearch.tsで本component使用時にprops.rightSideInputNameで
  // 指定したdisplay_idの値が右辺に表示されるようになる。
  @Prop({ default: '' })
  private rightSideInputName!: string;
  // インプット(右辺)内のdefault値
  @Prop({ default: '' })
  private rightSideDefaultValue?: string;
  // インプット(右辺)内の文字数制限
  @Prop({ default: '' })
  private rightSideInputMaxlength?: string;
  // インプット(右辺)の表示・非表示
  @Prop({ default: true })
  private isRightSideInputCodeVisible?: boolean;
  // インプット(右辺)の操作
  @Prop({ default: false })
  private isRightSideInputDisabled?: boolean;
  // インプット(右辺)要素プレースホルダ
  @Prop({ default: '' })
  private isRightSideInputPlaceholder?: string;
  // インプット(左辺)の読み取り専用状態
  @Prop({ default: false })
  private isRightSideInputReadOnly?: boolean;
  // ダイヤログ表示のタイトル
  @Prop({ default: '' })
  private dialogTitle?: string;
  // 領域外を押下した際の閉じる処理
  @Prop({ default: false })
  private isPersistent?: boolean;
  // 取得ボタンの表示・非表示
  @Prop({ default: true })
  private isBtnVisible?: boolean;
  // ラベルの表示・非表示
  @Prop({ default: true })
  private isShowLabel?: boolean;
  //参照元のデータベース項目ID
  @Prop({ default: '' })
  private masterId!: string;

  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns!: Array<any>;
  // テーブルに表示するデータ配列（親コンポーネントから受け取る値）
  @Prop({ default: () => [] })
  private body!: Array<any>;
  // テーブルに表示するデータ配列（子コンポーネントに受け渡す値）
  private bodyData: Array<any> = [];

  // rulesの付与
  @Prop({ default: '' })
  private rules?: string;

  // ダイヤログに表示するデータの非表示条件
  @Prop({ default: () => [] })
  private hideDataConditions!: {
    fieldId: string;
    value: string[];
  }[];

  // ダイアログの表示制御値
  private dialogStatus = false;

  // インプット(左辺)内の値
  leftSideInputValue = '';
  // インプット(右辺)内の値
  rightSideInputValue = '';

  private bgColor = '';

  /**
   * 初期値の設定
   */
  mounted() {
    if (this.value) {
      const findData = this.body.find(v => v.i_id === this.value.item_id);
      if (findData !== undefined) {
        this.leftSideInputValue = findData[this.masterId];
        this.rightSideInputValue = findData[this.rightSideInputName];
      }
    }
    if (this.rightSideDefaultValue) {
      this.rightSideInputName = this.rightSideDefaultValue;
    }
    this.hideDialogData();
  }

  @Watch('body')
  watchBody() {
    this.hideDialogData();
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      const findData = this.bodyData.find(v => v.i_id === this.value.item_id);
      if (findData !== undefined) {
        this.leftSideInputValue = findData[this.masterId];
        this.rightSideInputValue = findData[this.rightSideInputName];
      } else {
        this.leftSideInputValue = '';
        this.rightSideInputValue = '';
      }
    }
    if (this.rightSideDefaultValue) {
      this.rightSideInputName = this.rightSideDefaultValue;
    }
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperation
   * @return data - 名前と入力値
   */
  @Emit('inputOperation')
  public inputOperation(data: any) {
    this.leftSideInputValue = '';
    this.rightSideInputValue = '';
    this.bgColor = '';
    if (data !== '') {
      const findData = this.body.find(v => v[this.masterId] === data.value);
      if (findData !== undefined) {
        this.leftSideInputValue = findData[this.masterId];
        this.rightSideInputValue = findData[this.rightSideInputName];
        data.value = findData.i_id;
      } else {
        // 一致するデータがなければ値をクリアする
        data.value = '';
      }
    }
    return 'selectData' in data
      ? { name: this.leftSideInputName, value: data.selectData.i_id }
      : data;
  }

  /**
   * 親コンポーネントに入力値を通知する
   * @module inputOperationRelay
   * @return data - 名前と入力値
   */
  public catchTableDataRelay(data: any) {
    this.inputOperation(data);
    this.dialogStatus = false;
    this.leftSideInputValue = data.selectData[this.masterId];
    this.rightSideInputValue = data.selectData[this.rightSideInputName];
    return data.selectData;
  }

  /**
   * ダイアログを使用する場合のイベント取得処理
   * 子コンポーネントからEmitされる値をコンソールに出力
   * （値が渡されるか確認用）
   * @module changeDialogStatus
   * @param {Object} e - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  private changeDialogStatus(e: DialogStatusResponse): void {
    this.dialogStatus = false;
  }

  /**
   * ダイアログに表示するデータの非表示設定処理
   * （削除ステータスのデータをダイアログで非表示とする場合等に使用）
   * @module hideDialogData
   * @return {void}
   */
  private hideDialogData(): void {
    this.bodyData = this.body;
    this.hideDataConditions.forEach(condition => {
      this.bodyData = this.bodyData.filter(v => !condition.value.includes(v[condition.fieldId]));
    });
  }

  getErr(errors: any) {
    if (errors && errors.length > 0) {
      this.bgColor = 'error';
    } else {
      this.bgColor = '';
    }
  }
}
