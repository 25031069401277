

































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import MultipleLineText from '@/components/common/elements/MultipleLineText.vue';

@Component({
  components: { Label, MultipleLineText }
})
export default class TextArea extends Vue {
  // ラベルのname属性
  @Prop({ default: '' })
  private labelName!: string;
  // ラベル内テキスト
  @Prop({ default: '' })
  private labelText!: string;
  // 追加付与クラス
  @Prop({ default: '' })
  private labelClass?: string;
  // 入力要素のname属性
  @Prop({ default: '' })
  private name!: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // 入力要素高さ
  @Prop({ default: '5' })
  private rows?: string;
  // バリデーションルール
  @Prop({ default: '' })
  private rules?: string;

  /**
   * 親コンポーネントにinput要素名と値を通知する
   * @module inputOperation
   * @param {[k: string]: string} data - input要素名と値
   * @return {[k: string]: string} data - input要素名と値
   */
  @Emit('inputOperation')
  public inputOperation(data: { [k: string]: string }) {
    data.type = 'textarea';
    return data;
  }
}
