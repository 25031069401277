




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import Typography from '@/components/common/elements/Typography.vue';
import MultipleLineText from '@/components/common/elements/MultipleLineText.vue';

@Component({
  components: { Label, MultipleLineText, Typography }
})
export default class PopUpModal extends Vue {
  private dialog = false;
  // MAXモーダル幅
  @Prop({ default: '600' })
  private modalMaxWidth?: string;
  // ボタンラベル
  @Prop({ default: '' })
  private buttonLabel!: string;
  // ダイアログ内ボタン文字色
  @Prop({ default: '' })
  private buttonColor?: string;
  // 追加class
  @Prop({ default: '' })
  private areaClass?: string;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内OKボタン表示制御
  @Prop({ default: true })
  private isSubmit?: boolean;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonSubmitText?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: '' })
  private buttonSubmitColor?: string;
  // ダイアログ内Cancelボタン表示制御
  @Prop({ default: true })
  private isCancel?: boolean;
  // ダイアログ内Cancelボタン文字
  @Prop({ default: 'キャンセル' })
  private buttonCancelText?: string;
  // ダイアログ内Cancelボタン文字色
  @Prop({ default: '' })
  private buttonCancelColor?: string;

  // 入力要素のname属性
  @Prop({ default: '' })
  private name!: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 読み取り専用か
  @Prop({ default: false })
  private isReadonly?: boolean;
  // 入力要素高さ
  @Prop({ default: '5' })
  private rows?: string;
  // 確認ボタン直前の注意書き
  @Prop({ default: '' })
  private caution?: string;
  // Submitボタンの制御
  @Prop({ default: false })
  private isSubmitDisable?: boolean;
  // Submitボタンの制御
  @Prop({ default: false })
  private isCanselDisable?: boolean;

  /**
   * 親コンポーネントにinput要素名と値を通知する
   * @module onConfirm
   * @param {bool} Agree - true false
   * @return {bool}
   */
  @Emit('onConfirm')
  public onConfirm(isAgree: boolean) {
    this.dialog = !this.dialog;
    return isAgree;
  }
}
