/**
 *  ・クラス名
 *    ファイル取込み
 *  ・概要
 *    ファイル取込みに必要な処理を記述
 *  ・更新履歴
 *    2021/08/26 新規作成
 */
import BaseModel from '../baseModel';
import appState from '@/store/app';
import { HexaFileFieldResponse } from '@/services/hexabase/types';
import { fileActionMasterDefuneName, fileActionDefautMessage } from '@/constants/form/form';

export default class FileClass extends BaseModel {
  constructor() {
    super();
  }

  /**
   * ファイル削除時のメッセ―ジを取得します
   * @param {boolean} isStatus - 成功・失敗
   * @returns {string} - メッセージ
   */
  public getDeleteMessage(isStatus: boolean): string {
    const define = isStatus
      ? fileActionMasterDefuneName.delete.success
      : fileActionMasterDefuneName.delete.fail;
    const defaultMessage = isStatus
      ? fileActionDefautMessage.delete.success
      : fileActionDefautMessage.delete.fail;
    return this.resultMessage(define, defaultMessage);
  }

  /**
   * ファイルアップロード時のメッセ―ジを取得します
   * @param {boolean} isStatus - 成功・失敗
   * @returns {string} - メッセージ
   */
  public getUploadMessage(isStatus: boolean): string {
    const define = isStatus
      ? fileActionMasterDefuneName.upload.success
      : fileActionMasterDefuneName.upload.fail;
    const defaultMessage = isStatus
      ? fileActionDefautMessage.upload.success
      : fileActionDefautMessage.upload.fail;
    return this.resultMessage(define, defaultMessage);
  }

  /**
   * マスタにメッセージ定義が存在するか
   * @param {string} define - 定義名
   * @returns {string | null}
   */
  private getMessageProps(define: string): string | null {
    if ('common' in appState.options) {
      if ('form' in appState.options.common) {
        return define in appState.options.common.form ? appState.options.common.form[define] : null;
      }
    }
    return null;
  }
  private resultMessage(define: string, defaultMessage: string): string {
    const message = this.getMessageProps(define);
    return message === null ? defaultMessage : message;
  }

  /**
   * ファイルインプットルールの付与
   * @param {HexaFileFieldResponse[]} currentFiles
   * @param {string} rules
   * @returns {string}
   */
  public modifyRule(currentFiles: HexaFileFieldResponse[], rules?: string): string {
    if (rules === undefined || rules === null) {
      return '';
    }

    if (rules.length > 0) {
      const splitRule = rules.split('|');
      return splitRule
        .map(v => {
          if (v.includes('fileMaxCount')) {
            const index = v.indexOf(':');
            if (index === -1) return v;
            const masterCount = Number(v.substring(index).replace(':', ''));
            if (!Number.isInteger(masterCount)) return v;
            return [v.substring(0, index), masterCount - currentFiles.length].join(':');
          }
          if (v.includes('fileRequired')) {
            return undefined;
          }
          return v;
        })
        .filter(v => v)
        .join('|');
    }
    return '';
  }

  public getCountRule(currentFiles: HexaFileFieldResponse[], rules?: string): string {
    if (rules === undefined || rules === null) {
      return '';
    }
    if (rules.length > 0) {
      const splitRule = rules.split('|');
      return splitRule
        .map(v => {
          if (v.includes('fileRequired')) {
            return `${v}:${currentFiles.length}`;
          }
          return undefined;
        })
        .filter(v => v)
        .join('|');
    }
    return '';
  }

  /**
   * アイテムのファイルリスト取得
   * @param {string} appId - アプリID
   * @param {string} dsId - データストアID
   * @param {string} itemId - アイテムID
   * @returns {{ rev: number, files:HexaFileFieldResponse[] }}
   */
  public async getFileList(
    appId: string,
    dsId: string,
    itemId: string,
    fieldId: string
  ): Promise<{ rev: number; files: HexaFileFieldResponse[] }> {
    const result = await this.getItemDetails(appId, dsId, itemId, 'map');
    const revNo = result.rev_no;
    if (result.field_values && Array.isArray(result.field_values[fieldId].value)) {
      return {
        rev: revNo,
        files: result.field_values[fieldId].value as HexaFileFieldResponse[]
      };
    }
    return { rev: revNo, files: [] };
  }

  /**
   * ファイルのダウンロード
   * @param {{[k:string]: string}[]} fileList
   * @returns {void}
   */
  public async downLoadFiles(fileList: { [k: string]: string }[]): Promise<void> {
    for (const index in fileList) {
      const result = await this.getFile(fileList[index].file_id);
      this.formOutPutDownload(result, undefined, undefined, fileList[index]);
    }
  }

  /**
   * 指定ファイルの削除
   * @param {string} appId - アプリID
   * @param {string} dsId - データストアID
   * @param {string} itemId - アイテムID
   * @param {string} actionId - アクションID
   * @param {string} fieldId - フィールドID
   * @param {number} revNo - リビジョン番号
   * @param {{[k:string]: string}[]} selectItems - 削除対象ファイル情報
   * @param {HexaFileFieldResponse[]} currentFiles - 今現在アップロードされているファイル
   * @returns {void}
   */
  public async deleteFiles(
    appId: string,
    dsId: string,
    itemId: string,
    actionId: string,
    fieldId: string,
    revNo: number,
    selectItems: { [k: string]: string }[],
    currentFiles: HexaFileFieldResponse[]
  ): Promise<void> {
    for (const index in selectItems) {
      await this.deleteFile(itemId, fieldId, selectItems[index].file_id);
    }
    const del = selectItems.map(v => v.file_id);
    const current = currentFiles.map(v => v.file_id);
    await this.execAction(
      appId,
      dsId,
      itemId,
      actionId,
      revNo,
      current.filter(i => del.indexOf(i) == -1),
      fieldId
    );
  }

  /**
   * 対象ファイルをアップロード
   * @param {string} appId - アプリID
   * @param {string} dsId - データストアID
   * @param {string} itemId - アイテムID
   * @param {string} fieldId - フィールドID
   * @param {File[]} inputFiles - アップ対象ファイル
   * @returns
   */
  public async uploadFiles(
    appId: string,
    dsId: string,
    itemId: string,
    fieldId: string,
    inputFiles: File[]
  ) {
    const upfile: string[] = [];
    for (const i in inputFiles) {
      const formData = new FormData();
      formData.append('file', inputFiles[i]);
      formData.append('filename', inputFiles[i].name);
      formData.append('application_id', appId);
      formData.append('datastore_id', dsId);
      const result = await this.uploadFile(itemId, fieldId, formData);
      upfile.push(result.data.file_id);
    }
    return upfile;
  }

  public async execAction(
    appId: string,
    dsId: string,
    itemId: string,
    actionId: string,
    revNo: number,
    files: string[],
    fieldId: string
  ) {
    const params = {
      rev_no: revNo,
      item: {} as any
    };
    params.item[fieldId] = files;
    const result = await this.changeStatus(appId, dsId, itemId, actionId, params);
    this.apiErrorHandle('EXECUTEACTION', result);
  }
}
